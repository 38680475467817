import React from 'react';
import {connect} from "react-redux";
import {Accordion, AccordionBody, AccordionHeader, Button, Divider, FormGroup, Icon, Input, Label, Loading, Toast} from "spectre-react";
import TestInput from "../components/shared/Input";
import TestButton from "../components/shared/Button";
import {
    fetchUserIds, fetchCategories,
    fetchLastWagesParams, fetchAllWagesParams,
    postWagesParams, postUserIds, deleteNextWagesParams,
    postCategory, deleteCategory, postCategoryName
} from "../api";
import {pluralize} from "../components/shared/sharedImport";


class WagesParamsForm extends React.Component {
    state = {
        fetchState: 0, fetchError: undefined,
        submitState: 0, submitError: undefined,
        params: undefined
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({fetchState: 1, fetchError: undefined});

        try {
            const {isPast, isCurrent, isNext, ...params} = await fetchLastWagesParams();

            this.setState({fetchState: 2, isCurrent, isNext, params: params});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message});
        }
    }

    buildParamsFromForm(form) {
        // TODO: include startDate inside params?
        return {
            startDate: form.elements.startDate.value,
            basePrice: parseFloat(form.elements.basePrice.value),
            workTimeStart: form.elements.workTimeStart.value,
            workTimeEnd: form.elements.workTimeEnd.value,
            officeCoeff: parseFloat(form.elements.officeCoeff.value),
            workdayRegularCoeff: parseFloat(form.elements.workdayRegularCoeff.value),
            workdayOvertimeCoeff: parseFloat(form.elements.workdayOvertimeCoeff.value),
            weekendRegularCoeff: parseFloat(form.elements.weekendRegularCoeff.value),
            weekendOvertimeCoeff: parseFloat(form.elements.weekendOvertimeCoeff.value),
        };
    }

    onSubmit = async (ev) => {
        ev.preventDefault();

        const params = this.buildParamsFromForm(ev.target);

        // the form is uncontrolled, and setState here saves values that are submitted so they aren't reset to defaults in case of error
        this.setState({params});

        const isSuccess = await this.doSubmit(params);
        if (isSuccess) {
            setTimeout(() => this.setState({submitState: 0}), 1000);
            await this.fetchData();
        }
    }

    doSubmit = async (params) => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            await postWagesParams(params);
            this.setState({submitState: 2, params: params});
            return true;
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
            return false;
        }
    }

    render() {
        const {
            fetchState, fetchError, submitState, submitError,
            params, isCurrent, isNext
        } = this.state;

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        if (!params && fetchState !== 2)
            return <Loading large/>;

        const isSubmitting = submitState === 1;
        const isSuccessfulSubmit = submitState === 2;

        return (
            <Accordion>
                <AccordionHeader className="h4">
                    <Icon icon="arrow-right" className="mr-1"/> Коэффициенты для и расчета и другое
                </AccordionHeader>
                <AccordionBody>
                    <form id="wages-params-form" onSubmit={this.onSubmit} autoComplete="off">
                        <div className="mb-6 form-input-hint text-primary">
                            {isCurrent && <p>Будет создан новый набор коэффициентов. Обратите внимание, что дата начала не может быть раньше завтрашнего дня.</p>}
                            {isNext && <p>Будут отредактированы коэффициенты, которые еще не начали действовать. Обратите внимание, что дата начала в любом случае не может быть раньше завтрашнего дня.</p>}
                        </div>

                        <FormGroup>
                            <Label form small htmlFor="startDate">День начала действия этих коэфф-в</Label>
                            <TestInput type="date" name="startDate" id="startDate" loading={isSubmitting} defaultValue={params.startDate}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label form htmlFor="basePrice">Базовая цена</Label>
                            <TestInput type="text" name="basePrice" id="basePrice" loading={isSubmitting} defaultValue={params.basePrice} placeholder="1250"/>
                        </FormGroup>

                        <FormGroup>
                            <div className="columns">
                                <div className="column col-6">
                                    <Label form htmlFor="workTimeStart">Начало раб. времени</Label>
                                    <TestInput type="text" name="workTimeStart" id="workTimeStart" loading={isSubmitting} defaultValue={params.workTimeStart} placeholder="09:00" pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"/>
                                </div>
                                <div className="column col-6">
                                    <Label form htmlFor="workTimeEnd">Конец раб. времени</Label>
                                    <TestInput type="text" name="workTimeEnd" id="workTimeEnd" loading={isSubmitting} defaultValue={params.workTimeEnd} placeholder="18:00" pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"/>
                                </div>
                            </div>
                        </FormGroup>

                        <Divider/>

                        <FormGroup>
                            <Label form htmlFor="officeCoeff"><b>※</b> Коэфф. на время в офисе</Label>
                            <TestInput type="text" name="officeCoeff" id="officeCoeff" loading={isSubmitting} placeholder="0.8" defaultValue={params.officeCoeff}/>
                        </FormGroup>

                        <Divider/>

                        <FormGroup>
                            <Label form htmlFor="workdayRegularCoeff"><b>⁓</b> Коэфф. на авто, раб. время:</Label>
                            <TestInput type="text" name="workdayRegularCoeff" id="workdayRegularCoeff" placeholder="1" loading={isSubmitting} defaultValue={params.workdayRegularCoeff}/>
                        </FormGroup>
                        <FormGroup>
                            <Label form htmlFor="workdayOvertimeCoeff"><b>⁓</b> Коэфф. на авто, сверхурочно:</Label>
                            <TestInput type="text" name="workdayOvertimeCoeff" id="workdayOvertimeCoeff" loading={isSubmitting} placeholder="1.5" defaultValue={params.workdayOvertimeCoeff}/>
                        </FormGroup>
                        <FormGroup>
                            <Label form htmlFor="weekendRegularCoeff"><b>⁓</b> Коэфф. на авто, выходной,  раб. время:</Label>
                            <TestInput type="text" name="weekendRegularCoeff" id="weekendRegularCoeff" loading={isSubmitting} placeholder="1.5" defaultValue={params.weekendRegularCoeff}/>
                        </FormGroup>
                        <FormGroup>
                            <Label form htmlFor="weekendOvertimeCoeff"><b>⁓</b> Коэфф. на авто, выходной,  сверхурочно:</Label>
                            <TestInput type="text" name="weekendOvertimeCoeff" id="weekendOvertimeCoeff" loading={isSubmitting} placeholder="2" defaultValue={params.weekendOvertimeCoeff}/>
                        </FormGroup>

                        <Divider/>

                        <div className="mt-6">
                            {submitError && <Toast error className="my-2">{submitError}</Toast>}

                            <TestButton primary type="submit" loading={isSubmitting} successState={isSuccessfulSubmit}>Отправить</TestButton>
                        </div>
                    </form>
                </AccordionBody>
            </Accordion>
        )
    }
}


class WagesParamsHistory extends React.Component {
    state = {
        fetchState: 0, fetchError: undefined,
        deleteState: 0, deleteError: undefined,
        items: [],
    };

    onAccordionToggle = async (ev) => {
        if (ev.target.open && this.state.fetchState === 0) {
            await this.doFetchItems();
        }
    }

    doFetchItems = async () => {
        this.setState({fetchState: 1, fetchError: undefined, items: []});

        try {
            let items = await fetchAllWagesParams();
            items = items.map(({isPast, isCurrent, isNext, startDateDMY, ...rest}) => rest);

            this.setState({fetchState: 2, items});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message, items: []});
        }
    }

    onDeleteButtonClick = async () => {
        if (!window.confirm('Точно удалить?'))
            return;

        await this.doDeleteNextParams();
        await this.doFetchItems();
    }

    doDeleteNextParams = async () => {
        this.setState({deleteState: 1, deleteError: undefined});

        try {
            await deleteNextWagesParams();

            this.setState({deleteState: 0});
        } catch (error) {
            console.error(error);
            this.setState({deleteState: 0, deleteError: error.message});
        }
    }

    renderItem(params, index) {
        return (
            <Accordion open={index === 0} key={params.startDate}>
                <AccordionHeader className="mb-2 mt-4 c-hand">
                    <Icon icon="arrow-right" className="mr-1"/> {params.startDate} — {params.endDate ?? '...'}
                </AccordionHeader>
                <AccordionBody>
                    {params.isNext && (
                        <div className="mb-2">
                            Поскольку эти коэффициенты еще не начали действовать, их можно <span
                            className="c-hand text-bold text-primary" onClick={this.onDeleteButtonClick}>удалить</span>.
                        </div>
                    )}
                    <textarea className="form-input" rows={12} readOnly value={JSON.stringify(params, null, 4)}/>
                </AccordionBody>
            </Accordion>
        )
    }

    render() {
        const {fetchState, fetchError, items} = this.state;

        return (
            <Accordion onToggle={this.onAccordionToggle}>
                <AccordionHeader className="h4">
                    <Icon icon="arrow-right" className="mr-1"/> История коэффициентов
                </AccordionHeader>
                <AccordionBody>
                    {fetchError && <Toast error>{fetchError}</Toast>}
                    {fetchState === 1 && <Loading large/>}
                    {fetchState === 2 && items.map((params, index) => this.renderItem(params, index))}
                </AccordionBody>
            </Accordion>
        )
    }
}


class UserIdsForm extends React.Component {
    state = {
        fetchState: 0, fetchError: undefined,
        submitState: 0, submitError: undefined,
        driverId: null, managerIds: null, adminIds: null
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({fetchState: 1, fetchError: undefined});

        try {
            let {driverId, managerIds, adminIds} = await fetchUserIds();

            managerIds = managerIds.join(',');
            adminIds = adminIds.join(',');

            this.setState({fetchState: 2, driverId, managerIds, adminIds});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message});
        }
    }

    onSubmit = async (ev) => {
        ev.preventDefault();

        let driverId = ev.target.elements.driverId.value;
        let managerIds = ev.target.elements.managerIds.value;
        let adminIds = ev.target.elements.adminIds.value;

        // the form is uncontrolled, and setState here saves values that are submitted so they aren't reset to defaults in case of error
        this.setState({driverId, managerIds, adminIds});

        managerIds = managerIds.split(',').map(item => item.trim()).filter(Boolean);
        adminIds = adminIds.split(',').map(item => item.trim()).filter(Boolean);

        const isSuccess = await this.doSubmit(driverId, managerIds, adminIds);
        if (isSuccess) {
            setTimeout(() => this.setState({submitState: 0}), 1000);
            await this.fetchData();
        }
    }

    doSubmit = async (driverId, managerIds, adminIds) => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            await postUserIds({driverId, managerIds, adminIds});
            this.setState({submitState: 2});
            return true;
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
            return false;
        }
    }

    renderContent() {
        const {fetchState, submitState, submitError, driverId, managerIds, adminIds} = this.state;
        const {readOnly} = this.props;

        const isLoading = fetchState === 1 || submitState === 1;
        const isSuccessfulSubmit = submitState === 2;

        return (
            <form onSubmit={this.onSubmit} autoComplete="off">
                <FormGroup>
                    <Label form htmlFor="driverId">Водитель</Label>
                    <TestInput loading={isLoading} readOnly={readOnly} type="text" name="driverId" id="driverId" defaultValue={driverId}/>
                </FormGroup>
                <FormGroup>
                    <Label form htmlFor="managerIds">Менеджеры (управление списком поездок)</Label>
                    <TestInput loading={isLoading} readOnly={readOnly} type="text" name="managerIds" id="managerIds" defaultValue={managerIds}/>
                </FormGroup>
                <FormGroup>
                    <Label form htmlFor="adminIds">Админы (управление списком поездок и настройками)</Label>
                    <TestInput loading={isLoading} readOnly={readOnly} type="text" name="adminIds" id="adminIds" defaultValue={adminIds}/>
                </FormGroup>
                <div className="mt-6">
                    {submitError && <Toast error className="my-2">{submitError}</Toast>}
                    <TestButton primary type="submit" loading={isLoading} successState={isSuccessfulSubmit}>Сохранить</TestButton>
                </div>
            </form>
        );
    }

    render() {
        const {fetchError} = this.state;

        return (
            <Accordion>
                <AccordionHeader className="h4">
                    <Icon icon="arrow-right" className="mr-1"/> Люди
                </AccordionHeader>
                <AccordionBody>
                    {fetchError && <Toast error>{fetchError}</Toast>}
                    {!fetchError && this.renderContent()}
                </AccordionBody>
            </Accordion>
        )
    }
}


class CategoriesSection extends React.Component {
    state = {
        fetchState: 0, fetchError: undefined,
        submitState: 0, submitError: undefined,
        categories: []
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({fetchState: 1, fetchError: undefined});

        try {
            const categories = await fetchCategories(true);

            this.setState({fetchState: 2, categories});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message});
        }
    }

    onRemoveCategoryButtonClick = async (ev) => {
        const categoryId = ev.currentTarget.parentElement.parentElement.dataset.categoryId;

        await this.doRemoveCategory(categoryId);
        await this.fetchData();
    }

    doRemoveCategory = async (categoryId) => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            await deleteCategory(categoryId);
            this.setState({submitState: 2});
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
        }
    }

    onNewCategoryFormSubmit = async (ev) => {
        ev.preventDefault();

        const id = ev.target.elements.categoryId.value;
        const name = ev.target.elements.categoryName.value;

        await this.doCreateCategory(id, name);
        await this.fetchData();
    }

    doCreateCategory = async (id, name) => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            await postCategory({id, name});
            this.setState({submitState: 2});
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
        }
    }

    onChangeCategoryNameButtonClick = async (ev) => {
        const categoryId = ev.currentTarget.parentElement.parentElement.dataset.categoryId;
        const result = window.prompt("Новое имя для категории:");

        if (result === null || !result.trim())
            return;

        await this.doChangeCategoryName(categoryId, result.trim());
        await this.fetchData();
    }

    doChangeCategoryName = async (categoryId, name) => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            await postCategoryName(categoryId, name);
            this.setState({submitState: 2});
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
        }
    }

    renderContent() {
        const {submitState, submitError, categories} = this.state;
        const isLoading = submitState === 1;

        return (
            <div>
                <ul id="categories">
                {categories.map(({id, name, coeff, removable, ridesCount}) => (
                    <li key={id} className="category" data-category-id={id}>
                        <span className="text-ellipsis">
                            <Button action small link type="button" className="mr-2" onClick={this.onChangeCategoryNameButtonClick}>
                                <Icon icon="edit"/>
                            </Button>
                            <span>{name}</span>
                        </span>
                        <span>ID: <i>{id}</i></span>
                        <span>{ridesCount} {pluralize(ridesCount, "поездок", "поездка", "поездки", "поездок")}</span>
                        <span>Коэфф.: {coeff}</span>
                        <span>
                            <Button action small link type="button" onClick={this.onRemoveCategoryButtonClick} disabled={!removable || ridesCount > 0}>
                                <Icon icon="cross"/>
                            </Button>
                        </span>
                    </li>
                ))}
                </ul>

                <form id="new-category-form" className="mt-2" onSubmit={this.onNewCategoryFormSubmit}>
                    <TestInput loading={isLoading} name="categoryName" id="categoryName" placeholder="Название" className="mr-2"/>
                    <TestInput loading={isLoading} name="categoryId" id="categoryId" placeholder="ID"/>

                    <TestButton primary type="submit" className="ml-2" loading={isLoading}>Добавить</TestButton>
                </form>

                {submitError && <Toast error className="my-2">{submitError}</Toast>}
            </div>
        )
    }

    render() {
        const {fetchState, fetchError} = this.state;

        return (
            <Accordion>
                <AccordionHeader className="h4">
                    <Icon icon="arrow-right" className="mr-1"/> Категории
                </AccordionHeader>
                <AccordionBody>
                    {fetchError && <Toast error>{fetchError}</Toast>}
                    {fetchState === 1 && <Loading large/>}
                    {fetchState === 2 && this.renderContent()}
                </AccordionBody>
            </Accordion>
        )
    }
}


class SettingsPage extends React.PureComponent {
    render() {
        const currentUser = this.props.currentUser;
        const {isManagement, isAdmin} = currentUser;

        return (
            <div className="container grid-sm" id="settings-page">
                {isAdmin && <UserIdsForm/>}

                <Divider className="my-4"/>

                {isManagement && <CategoriesSection />}

                <Divider className="my-4"/>

                {isAdmin && <WagesParamsForm/>}

                <Divider className="my-4"/>

                {isAdmin && <WagesParamsHistory />}
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.currentUser,
    }
};

export default connect(mapStateToProps, null)(SettingsPage);