import React from "react";
import PropTypes from "prop-types";
import {Divider} from "spectre-react";
import {lightFormat} from "date-fns";


class CalculationString extends React.PureComponent {
    static propTypes = {
        coeffInfo: PropTypes.objectOf(PropTypes.shape({
            color: PropTypes.string,
            description: PropTypes.string,
        })),
        string: PropTypes.string,
        prefix: PropTypes.string,
    }

    static defaultProps = {
        prefix: 'Расчет: ',
    }

    constructor(props) {
        super(props);

        const abstractString = props.prefix + props.string.replace(/{(\d):(.*?)}/g, (match, p1, p2, offset, string, groups) => {
            const coeff = `k${p1}`;
            const className = props.coeffInfo[coeff].color;
            return `<span class="${className}">${coeff}</span>`;
        });
        const explicitString = props.prefix + props.string.replace(/{(\d):(.*?)}/g, (match, p1, p2, offset, string, groups) => {
            const coeff = `k${p1}`;
            const className = props.coeffInfo[coeff].color;
            return `<span class="${className}">${p2}</span>`;
        });

        this.state = {
            opened: false,
            abstractString: {__html: abstractString},
            explicitString: {__html: explicitString},
        }
    }

    toggleStrings = () => this.setState(({opened}) => ({opened: !opened}));

    render() {
        const {opened, abstractString, explicitString} = this.state;

        if (opened) {
            const coeffDivs = Object.keys(this.props.coeffInfo)
                .sort((a, b) => a.localeCompare(b))
                .map(coeff => {
                    const {color, description} = this.props.coeffInfo[coeff];
                    return <div key={coeff} className={color}>{coeff} -- {description}</div>;
                });

            return (
                <div className="text-italic">
                    <div className="c-hand" onClick={this.toggleStrings} dangerouslySetInnerHTML={abstractString}></div>
                    {coeffDivs}
                </div>
            );
        } else {
            return <div className="text-italic c-hand" onClick={this.toggleStrings} dangerouslySetInnerHTML={explicitString}/>;
        }
    }
}


class DayContent extends React.PureComponent {
    static COEFF_INFO = {
        work: {
            'k1': {color: 'orange', description: 'кф. категории поездки (личные = 0)'},
            'k2': {color: 'olive', description: 'один из 4 кф. [раб., сверхур.] x [будний, вых.]'},
        },
        complement: {
            'k1': {color: 'purple', description: 'кф. доп. часов'},
        }
    }

    static propTypes = {
        showDetailedPayAmount: PropTypes.bool.isRequired,
        timelineDay: PropTypes.object,
    }

    render() {
        const showDetailedPayAmount = this.props.showDetailedPayAmount;
        const isTimelineDaySelected = !!this.props.timelineDay;
        const timelineDay = {...this.props.timelineDay};

        if (!timelineDay)
            return null;

        for (const key in timelineDay) {
            if (typeof timelineDay[key] === "number")
                timelineDay[key] = timelineDay[key].toFixed(2);
        }

        return (
            <div id="day-content" className="text-preline">
                {!isTimelineDaySelected && <div>Выберите день.</div>}

                {isTimelineDaySelected && <div>Начислено всего: {timelineDay.payAmount} ₽</div>}

                {timelineDay.carHoursSum > 0 && <Divider />}

                {timelineDay.carHoursSum > 0 && <div>В авто в раб. время: {timelineDay.carHoursRegular} ч</div>}
                {timelineDay.carHoursSum > 0 && <div>В авто сверхурочно: {timelineDay.carHoursOvertime} ч</div>}
                {timelineDay.carHoursSum > 0 && <div>Всего часов в авто: {timelineDay.carHoursSum} ч</div>}
                {timelineDay.carHoursSum > 0 && <div>Начислено за авто: {timelineDay.carHoursPayAmount} ₽</div>}
                {timelineDay.carHoursSum > 0 && showDetailedPayAmount && <CalculationString key={timelineDay.carHoursCalc} coeffInfo={DayContent.COEFF_INFO.work} string={timelineDay.carHoursCalc} />}

                {timelineDay.officeHoursSum > 0 && <Divider />}

                {timelineDay.officeHoursSum > 0 && <div>В офисе: {timelineDay.officeHoursSum} ч</div>}
                {timelineDay.officeHoursSum > 0 && <div>Начислено за время в офисе: {timelineDay.officeHoursPayAmount} ₽</div>}
                {timelineDay.officeHoursSum > 0 && showDetailedPayAmount && <CalculationString key={timelineDay.officeHoursCalc} coeffInfo={DayContent.COEFF_INFO.complement} string={timelineDay.officeHoursCalc} />}

                {timelineDay.vacationPayAmount > 0 && <div>Отпускные: {timelineDay.vacationPayAmount} ₽</div>}

                {timelineDay.updatedAt && showDetailedPayAmount && <Divider />}
                {timelineDay.updatedAt && showDetailedPayAmount && <div className="text-italic">Обновлено: {lightFormat(new Date(timelineDay.updatedAt), 'dd.MM.yyyy HH:mm')}</div>}

                {/*<div>{JSON.stringify(timelineDay, null, 2)}</div>*/}
            </div>
        );
    }
}


export default DayContent;