import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import {FormGroup, Label, Input, Toast, Button, Loading} from "spectre-react";
import * as paths from '../paths';
import {checkAuth, signIn} from '../api';
import {signedIn} from "../actions";


class SignInPage extends React.Component {
    static propTypes = {
        onSignIn: PropTypes.func.isRequired,
    }

    state = {
        name: '', password: '',
        loadingState: 1, loadingError: undefined,
        submitState: 0, submitError: undefined,
    };

    componentDidMount() {
        this.checkAlreadyLoggedIn();
    }

    checkAlreadyLoggedIn = async () => {
        try {
            const data = await checkAuth();

            this.props.onSignIn(data);
            this.setState({loadingState: 2, loadingError: undefined});
        } catch (error) {
            if (error.response) {
                if (error.response.status !== 401)
                    this.setState({loadingState: 0, loadingError: error.message})
                else
                    this.setState({loadingState: 0});
            }
        }
    }

    onSubmit = async (ev) => {
        ev.preventDefault();

        this.setState({submitState: 1});

        try {
            const name = ev.target.elements.name.value;
            const password = ev.target.elements.password.value;
            const data = await signIn(name, password);

            this.props.onSignIn(data);
            this.setState({submitState: 2, submitError: undefined});
        } catch (error) {
            this.setState({submitState: 0, submitError: error.message})
        }
    };

    render() {
        const {loadingState, loadingError, submitState, submitError} = this.state;

        if (loadingError)
            return <Toast error className="mt-4">{loadingError}</Toast>

        if (loadingState === 1)
            return <Loading large/>;

        if (loadingState === 2 || submitState === 2)
            return <Redirect to={this.props.location.state?.from || paths.DEFAULT_PATH} />

        return (
            <form id="sign-in-form" onSubmit={this.onSubmit}>
                <img src="/logo.svg" className="img-responsive mb-4" alt="Логотип"/>

                <FormGroup>
                    <Label form htmlFor="name">Логин</Label>
                    <Input type="text" id="name" name="name" autoFocus/>
                </FormGroup>

                <FormGroup>
                    <Label form htmlFor="password">Пароль</Label>
                    <Input type="password" id="password" name="password"/>
                </FormGroup>

                {submitError && <Toast className="mt-4">{submitError}</Toast>}
                <Button primary loading={submitState === 1} type="submit" className="mt-4">Войти</Button>
            </form>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSignIn: (payload) => dispatch(signedIn(payload))
});

export default connect(null, mapDispatchToProps)(SignInPage);