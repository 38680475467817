import React from "react";
import PropTypes from "prop-types";
import {lightFormat} from "date-fns";
import {removeTDO} from "../../api";
import cx from "classnames";
import {Button, Icon} from "spectre-react";

class OverridesLog extends React.PureComponent {
    static propTypes = {
        overrides: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string,
            _rev: PropTypes.string,
            authorId: PropTypes.string,
            affectedEngineerId: PropTypes.string,
            affectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
            statusOverride: PropTypes.string,
            createdAt: PropTypes.instanceOf(Date),

            iconClassName: PropTypes.string,
        })).isRequired,
        id: PropTypes.string,
        className: PropTypes.string,
        onItemRemoved: PropTypes.func,
        showItemRemoveButtons: PropTypes.bool,
    }

    state = {
        itemsShownNum: 6,
    }

    getAffectedDatesText(affectedDates) {
        return affectedDates.length === 1
            ? affectedDates[0]
            : `${affectedDates[0]} – ${affectedDates[1]}`;
    }

    renderDayStatusOverrideItem(item) {
        const affectedDatesText = this.getAffectedDatesText(item.affectedDatesRU);
        const createdAt = lightFormat(item.createdAt, 'dd.MM.yyyy HH:mm:ss');

        return (
            <div key={+item.createdAt} className="tile tile-centered" data-doc-id={item._id} data-doc-rev={item._rev}>
                <div className={`tile-icon ${item.iconClassName}`}></div>
                <div className="tile-content">
                    <div className="tile-title">{affectedDatesText}</div>
                    <div className="tile-subtitle text-gray text-small">{item.authorId} · {createdAt}</div>
                </div>
                <div className="tile-action">
                    {this.props.showItemRemoveButtons &&
                        <Button link onClick={this.onItemRemoveButtonClick}>
                            <Icon icon="cross"/>
                        </Button>
                    }
                </div>
            </div>
        )
    }

    onItemRemoveButtonClick = async (ev) => {
        let parent = ev.target;
        while (!parent.classList.contains('tile'))
            parent = parent.parentElement;

        const {docId, docRev} = parent.dataset;
        const response = await removeTDO(docId, docRev);

        if (this.props.onItemRemoved)
            this.props.onItemRemoved(response);
    }

    onShowMoreItemsButtonClick = () => {
        const overrides = this.props.overrides;
        this.setState(state => ({itemsShownNum: Math.min(state.itemsShownNum + 6, overrides.length)}));
    }

    renderShowMoreButton() {
        if (this.state.itemsShownNum >= this.props.overrides.length)
            return null;

        return (
            <Button link block small className="mt-2"
                    onClick={this.onShowMoreItemsButtonClick}>
                Показать больше
            </Button>
        );
    }

    render() {
        const id = this.props.id;
        const className = cx("day-status-overrides", this.props.className);
        const overrides = this.props.overrides.slice(0, this.state.itemsShownNum);

        return (
            <div id={id} className={className}>
                {overrides.map(item => this.renderDayStatusOverrideItem(item))}
                {this.renderShowMoreButton()}
            </div>
        )
    }
}

export default OverridesLog;