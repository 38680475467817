export const RIDES_DOC_STATE = {
    RAW: 'RAW',
    GROUPED: 'GROUPED',
    FINISHED: 'FINISHED'
}

// export const DAY_STATUS = {
//     VACATION_PAID: 'VACATION_PAID',
//     VACATION_UNPAID: 'VACATION_UNPAID',
//     TIME_OFF_PAID: 'TIME_OFF_PAID',
//     TIME_OFF_UNPAID: 'TIME_OFF_UNPAID',
//     OFFICE: 'OFFICE',
//     OFFICE_NWD: 'OFFICE_NWD',
//     FIELD: 'FIELD',
//     FIELD_NWD: 'FIELD_NWD',
//     ILLNESS: 'ILLNESS',
//     EMPTY: 'EMPTY',
// };
//
// export const WORK_PLACE = {
//     OFFICE: 'OFFICE',
//     FIELD: 'FIELD',
// }
//
// export const PAYROLL_METHOD = {
//     WORKTIME: 'WORKTIME',  // regular hours are 10-19, everything else is overtime
//     SHIFT: 'SHIFT',  // regular hours are up to 8 in any time of day, everything over 8 hours is overtime
// };