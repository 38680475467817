import axios from 'axios';


function returnResponseData(response) {
    return response.data;
}

function throwError(error) {
    if (error.response)
        throw new Error(`${error.response.data.reason} (${error.response.status})`);
    else
        throw error;
}

export function signIn(name, password) { return axios.post('/api/session', {name, password}).then(returnResponseData).catch(throwError); }
export function signOut() { return axios.delete('/api/session').then(returnResponseData).catch(throwError); }
export function checkAuth() { return axios.get('/api/session').then(returnResponseData) }

export function fetchUserIds() { return axios.get(`/api/settings/userIds`).then(returnResponseData).catch(throwError); }
export function postUserIds(data) { return axios.post(`/api/settings/userIds`, data).then(returnResponseData).catch(throwError); }

export function fetchCategories(includeRidesCount=false) { return axios.get(`/api/settings/categories?ridesCount=${includeRidesCount}`).then(returnResponseData).catch(throwError); }
export function postCategory(data) { return axios.post(`/api/settings/categories`, data).then(returnResponseData).catch(throwError); }
export function postCategoryName(categoryId, name) { return axios.post(`/api/settings/categories/${categoryId}/name`, {name}).then(returnResponseData).catch(throwError); }
export function deleteCategory(categoryId) { return axios.delete(`/api/settings/categories/${categoryId}`).then(returnResponseData).catch(throwError); }

export function fetchRides(startDate, endDate, sort) { return axios.get(`/api/rides?startDate=${startDate}&endDate=${endDate}&sort=${sort}`).then(returnResponseData).catch(throwError); }
export function toggleRideActiveStatus(date, rideIndex) { return axios.post(`/api/rides/${date}/${rideIndex}/active`).then(returnResponseData).catch(throwError); }
export function toggleRideLinkedStatus(date, rideIndex) { return axios.post(`/api/rides/${date}/${rideIndex}/linked`).then(returnResponseData).catch(throwError); }
export function fetchSingleDayRides(date) { return axios.get(`/api/rides/${date}`).then(returnResponseData).catch(throwError); }
export function submitRideGroups(date) { return axios.post(`/api/rides/${date}/groups`).then(returnResponseData).catch(throwError); }
export function removeRideGroups(date) { return axios.delete(`/api/rides/${date}/groups`).then(returnResponseData).catch(throwError); }
export function submitRideGroupsInfo(date, data) { return axios.put(`/api/rides/${date}/groups`, data).then(returnResponseData).catch(throwError); }

export function postManualRide(date, data) { return axios.post(`/api/rides/${date}/manual`, data).then(returnResponseData).catch(throwError); }
export function editManualRide(date, index, data) { return axios.put(`/api/rides/${date}/manual/${index}`, data).then(returnResponseData).catch(throwError); }
export function removeManualRide(date, index) { return axios.delete(`/api/rides/${date}/manual/${index}`).then(returnResponseData).catch(throwError); }

export function fetchLastWagesParams() { return axios.get(`/api/settings/wagesparams?only=last`).then(returnResponseData).catch(throwError); }
export function fetchCurrentWagesParams() { return axios.get(`/api/settings/wagesparams?only=current`).then(returnResponseData).catch(throwError); }
export function fetchAllWagesParams() { return axios.get(`/api/settings/wagesparams`).then(returnResponseData).catch(throwError); }
export function postWagesParams(data) { return axios.post('/api/settings/wagesparams', data).then(returnResponseData).catch(throwError); }
export function deleteNextWagesParams() { return axios.delete('/api/settings/wagesparams').then(returnResponseData).catch(throwError); }

export function getTimelineData(startDate, endDate) { return axios.get(`/api/timeline/cached?startDate=${startDate}&endDate=${endDate}`).then(returnResponseData).catch(throwError); }
export function getTimelineDayActions(days) { return axios.post(`/api/timeline/actions`, {days}).then(returnResponseData).catch(throwError); }
export function createTDO(data) { return axios.post(`/api/timeline/calendar/overrides`, data).then(returnResponseData).catch(throwError); }
export function removeTDO(docId, docRev) { return axios.delete(`/api/timeline/calendar/overrides/${docId}?rev=${docRev}`).then(returnResponseData).catch(throwError); }

export function fetchTimelineStats(startDate, endDate, computed=false) { return axios.get(`/api/timeline/stats?start=${startDate}&end=${endDate}&computed=${computed}`).then(returnResponseData).catch(throwError); }