import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import * as paths from '../paths';
import {signOut} from "../api";
import {signedOut} from "../actions";
import {Button, Divider} from "spectre-react";


class Navbar extends React.Component {
    signOut = () => {
        signOut()
            .then(this.props.onSignOut)
            .catch(error => alert(error.message));
    };

    render() {
        const currentUser = this.props.currentUser;
        const {isManagement, isAdmin} = currentUser;

        return (
            <div id="navbar">
                <header className="navbar mb-2">
                    <section className="navbar-section hide-lg">
                        <NavLink to={paths.RIDES_PATH}>
                            <img src='/logo.svg' className="img-responsive" alt="Логотип SignArt" />
                        </NavLink>
                    </section>

                    <section className="navbar-section">
                        <NavLink className="btn btn-link" exact to={paths.RIDES_PATH}><span className="material-icons">local_shipping</span></NavLink>
                        {isManagement && <NavLink className="btn btn-link" to={paths.SETTINGS_PATH}><span className="material-icons">settings</span></NavLink>}
                        <NavLink className="btn btn-link" exact to={paths.TIMELINE_PATH}><span className="material-icons">calendar_month</span></NavLink>
                        {isAdmin && <NavLink className="btn btn-link" exact to={paths.WAGES_TABLE_PATH}>ОТ-E</NavLink>}
                        <Button link onClick={this.signOut}>Выйти</Button>
                    </section>
                </header>

                <Divider id="divider"/>
            </div>
        );
    }
}

export default connect(null, {onSignOut: signedOut})(Navbar);