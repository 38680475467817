import {endOfQuarter, lightFormat, startOfQuarter} from "date-fns";
import {
    SIGNED_IN,
    SIGNED_OUT,
    SET_PAGE_FILTERS
} from './actions';

const STATE = {
    currentUser: null,
    driverId: null,
    pageFilters: {
        startDate: lightFormat(startOfQuarter(new Date()), 'yyyy-MM-dd'),
        endDate: lightFormat(endOfQuarter(new Date()), 'yyyy-MM-dd'),

        displayType: "twoTables",
        showStats: true,
        timespanMode: 'quarter',
    }
};

export default (state = STATE, action) => {

    if (action.type === SIGNED_IN) {
        const {user, driverId} = action.payload;
        const { _id, isDriver, isManager, isAdmin } = user;
        const currentUser = { _id, isDriver, isManager, isAdmin, isManagement: isManager || isAdmin }

        return {...state, currentUser, driverId};
    }

    if (action.type === SIGNED_OUT) {
        return {...state, currentUser: null, driverId: null};
    }

    if (action.type === SET_PAGE_FILTERS) {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                ...action.payload
            }
        }
    }

    return state;
};