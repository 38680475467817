export function basename(path) { return path.substring(path.lastIndexOf('/') + 1) }
export function dirname(path) { return path.substring(0, path.lastIndexOf('/')); }

export const dateLocale = {
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdaysLong: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота',],
    weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
    firstDayOfWeek: 1,
    locale: 'ru',
    labels: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' }
};

// const monthsGenitive = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
// function humanDate(date, short = false) {
//     const t = date instanceof Date
//         ? [date.getDate(), monthsGenitive[date.getMonth()], date.getFullYear()]
//         : [date.slice(-2), monthsGenitive[parseInt(date.slice(5, 7), 10) - 1], date.slice(0, 4)];
//
//     if (short)
//         t[1] = t[1].slice(0, 3);
//
//     return t.join(' ');
// }

// https://stackoverflow.com/a/41716722
export function roundToOne(num) {
    return Math.round( ( num + Number.EPSILON ) * 10 ) / 10;
}

// https://stackoverflow.com/a/41716722
export function roundToTwo(num) {
    return Math.round( ( num + Number.EPSILON ) * 100 ) / 100;
}

export function formatDateTime2(d) { return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth()+1)).slice(-2) + "." + d.getFullYear() + ", " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2); }
export function formatDate1(d) { return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2); }
export function formatDate2(d) { return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear(); }
export function formatDate2s(d) { return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth()+1)).slice(-2) + "." + ("" + d.getFullYear()).slice(-2); }

export function date1to2(str) { return str.slice(8) + '.' + str.slice(5,7) + '.' + str.slice(0,4); }
export function date1to2s(str) { return str.slice(8) + '.' + str.slice(5,7) + '.' + str.slice(2,4); }
export function date2to1(str) { return str.slice(6) + '-' + str.slice(3,5) + '-' + str.slice(0,2); }

export function parseDate2(str) {
    if (!(typeof(str) === "string") || !/^\d\d\.\d\d\.\d\d\d\d$/.test(str)) return undefined;
    const value = new Date(str.slice(6, 10) + '-' + str.slice(3, 5) + '-' + str.slice(0, 2));
    if (isNaN(value.valueOf())) return undefined;
    return value;
}

export function parseDate(str) {
    if (str instanceof Date)
        return new Date(str);
    else if (/^\d\d\d\d-\d\d-\d\d$/.test(str))
        return new Date(str);
    else if (/^\d\d\.\d\d\.\d\d\d\d$/.test(str))
        return new Date(str.slice(6, 10) + '-' + str.slice(3, 5) + '-' + str.slice(0, 2));
    // else if (/^\d\d\.\d\d\.\d\d$/.test(str))
    //     return new Date('20' + str.slice(6, 8) + '-' + str.slice(3, 5) + '-' + str.slice(0, 2));
    else
        return undefined;
}

export function isMobile() { return (/Mobi|Android/i.test(navigator.userAgent)); }

// https://github.com/kulakowka/pluralize-ru
export const pluralize = (i, str0, str1, str2, str5) => {
    if (i === 0)
        return str0;
    else if (i % 10 === 1 && i % 100 !== 11)
        return str1;
    else if (i % 10 >= 2 && i % 10 <= 4 && (i % 100 < 10 || i % 100 >= 20))
        return str2;
    else
        return str5;
};

export function debounce(func, timeout = 300) {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const DAY_STATUS = {
    VACATION_PAID: 'VACATION_PAID',
    VACATION_UNPAID: 'VACATION_UNPAID',
    TIME_OFF_PAID: 'TIME_OFF_PAID',
    TIME_OFF_UNPAID: 'TIME_OFF_UNPAID',
    OFFICE: 'OFFICE',
    OFFICE_NWD: 'OFFICE_NWD',
    FIELD: 'FIELD',
    FIELD_NWD: 'FIELD_NWD',
    ILLNESS: 'ILLNESS',
    EMPTY: 'EMPTY',
};

export const DAY_STATUS_LABEL = {
    VACATION_PAID: 'Оплачиваемый отпуск',
    VACATION_UNPAID: 'Неоплачиваемый отпуск',
    TIME_OFF_PAID: 'Оплачиваемый отгул',
    TIME_OFF_UNPAID: 'Неоплачиваемый отгул',
    OFFICE: 'В офисе',
    OFFICE_NWD: 'В офисе в нерабочий день',
    FIELD: 'В автомобиле',
    FIELD_NWD: 'В автомобиле в нерабочий день',
    ILLNESS: 'Больничный',
    EMPTY: 'Неопределено',
};