import React from 'react';
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import * as paths from '../paths';

import Navbar from './Navbar';
import SignInPage from '../pages/SignInPage';
import RidesPage from "../pages/RidesPage";
import SettingsPage from "../pages/SettingsPage";
import WagesTablePage from "../pages/WagesTablePage";
import CalendarPage from "../pages/TimelinePage";
// import WagesParamsEditPage from "../pages/WagesParamsEditPage";
// import WagesParamsHistoryPage from "../pages/WagesParamsHistoryPage";


const AuthenticatedRoute = ({component: Component, authenticated, currentUser, ...rest}) => (
    <Route {...rest} render={(props) => (
        authenticated === true
            ? <Component {...props} currentUser={currentUser} />
            : <Redirect to={{pathname: paths.SIGN_IN_PATH, state: {from: props.location}}}/>
    )} />
);

const ManagementRoute = ({component: Component, authenticated, currentUser, ...rest}) => (
    <Route {...rest} render={(props) => (
        authenticated === true && currentUser.isManagement
            ? <Component {...props} currentUser={currentUser} />
            : <Redirect to={{pathname: paths.SIGN_IN_PATH, state: {from: props.location}}}/>
    )} />
);


class App extends React.Component {
    render() {
        const {currentUser} = this.props;
        const authenticated = !!currentUser;

        return (
            <Router>
                {authenticated && <Navbar currentUser={currentUser}/>}

                <Switch>
                    <Route exact path="/" component={() => <Redirect to={paths.SIGN_IN_PATH}/>}/>
                    <Route exact path={paths.SIGN_IN_PATH} component={SignInPage}/>

                    <AuthenticatedRoute exact path={paths.RIDES_PATH} component={RidesPage} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.TIMELINE_PATH} component={CalendarPage} currentUser={currentUser} authenticated={authenticated}/>

                    <ManagementRoute exact path={paths.SETTINGS_PATH} component={SettingsPage} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute path={paths.WAGES_TABLE_PATH} component={WagesTablePage} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact component={() => <h1>404 Not Found</h1>} authenticated={authenticated}/>
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = ({replProgress, currentUser}) => ({replProgress, currentUser});

export default connect(mapStateToProps, null)(App);