import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from "classnames";
import {lightFormat, getYear, isValid} from 'date-fns';
import {FormGroup, Loading, Radio, Divider, Checkbox, Toast, Accordion, AccordionHeader, Icon, AccordionBody} from "spectre-react";
import TimeNavigation from "../components/shared/TimeNavigation";
import rubFmt from "../components/shared/summaFormatter";
import {fetchCurrentWagesParams, fetchTimelineStats} from "../api";
import {pluralize, roundToOne, roundToTwo} from "../components/shared/sharedImport";
import {setPageFilters} from "../actions";


class WagesParamsDemonstration extends React.PureComponent {
    state = {
        isLoading: 0, loadingError: undefined, params: undefined
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({usLoading: true, loadingError: undefined});

        try {
            const params = await fetchCurrentWagesParams();
            this.setState({usLoading: false, params});
        } catch (error) {
            console.error(error);
            this.setState({usLoading: false, loadingError: error.message});
        }
    }

    render() {
        const {isLoading, loadingError, params} = this.state;

        if (loadingError)
            return <Toast error>{loadingError}</Toast>;

        if (isLoading || !params)
            return <Loading large />;

        return (
            <Accordion>
                <AccordionHeader className="h5">
                    <Icon icon="arrow-right" className="mr-1"/> Коэффициенты <span className="text-gray text-tiny">активны c {params.startDateDMY}</span>
                </AccordionHeader>
                <AccordionBody>
                    <div className="columns">
                        <div className="column col-6 col-lg-12">
                            <div className="d-flex">
                                <div className="flex-1">Базовая цена:</div>
                                <div className="flex-1">{params.basePrice} ₽/час</div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">Рабочее время:</div>
                                <div className="flex-1">{params.workTimeStart} — {params.workTimeEnd}</div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">Коэфф. на время в офисе:</div>
                                <div className="flex-1">{params.officeCoeff}</div>
                            </div>
                        </div>
                        <div className="column col-6 col-lg-12">
                            <div className="d-flex">
                                <div className="flex-1">Коэфф. на авто, раб. время:</div>
                                <div className="flex-1">{params.workdayRegularCoeff}</div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">Коэфф. на авто, сверхурочно:</div>
                                <div className="flex-1">{params.workdayOvertimeCoeff}</div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">Коэфф. на авто, выходной,  раб. время:</div>
                                <div className="flex-1">{params.weekendRegularCoeff}</div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1">Коэфф. на авто, выходной,  сверхурочно:</div>
                                <div className="flex-1">{params.weekendOvertimeCoeff}</div>
                            </div>
                        </div>
                    </div>
                </AccordionBody>
            </Accordion>
        )
    }
}


class WagesTablePage extends React.PureComponent {
    static propTypes = {
        pageFilters: PropTypes.object,
        setPageFilters: PropTypes.func,
    };

    state = {
        isLoading: true,
        loadingError: undefined,

        // startDate: lightFormat(startOfQuarter(new Date()), 'yyyy-MM-dd'),
        // endDate: lightFormat(endOfQuarter(new Date()), 'yyyy-MM-dd'),
        //
        // displayType: "twoTables",
        // showStats: true,

        stats: undefined,
    }


    componentDidMount() {
        this.fetchTimelineStats();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.pageFilters.startDate !== this.props.pageFilters.startDate ||
            prevProps.pageFilters.endDate !== this.props.pageFilters.endDate
        )
            this.fetchTimelineStats();
    }

    fetchTimelineStats = async () => {
        if (!this.areStartAndEndDateValid())
            return;

        this.setState({isLoading: true, loadingError: undefined});
        const {startDate, endDate} = this.props.pageFilters;

        try {
            const stats = await fetchTimelineStats(startDate, endDate, true);
            this.setState({isLoading: false, stats});
        } catch (error) {
            console.error(error);
            this.setState({isLoading: false, loadingError: error});
        }
    };


    onTimespanChange = ({startDate, endDate, mode}) => {
        startDate = lightFormat(startDate, 'yyyy-MM-dd');
        endDate = lightFormat(endDate, 'yyyy-MM-dd');
        this.props.setPageFilters({startDate, endDate, timespanMode: mode});
    }

    onDisplayTypeChange = (ev) => {
        this.props.setPageFilters({displayType: ev.target.value});
    }

    onShowStatsChange = (ev) => { this.props.setPageFilters({showStats: ev.target.checked}); }


    areStartAndEndDateValid() {
        const {startDate, endDate} = this.props.pageFilters;

        if (!startDate || !endDate)
            return false;

        const start = new Date(startDate), end = new Date(endDate);

        if (!isValid(start) || !isValid(end))
            return false;

        return start <= end &&
            2010 <= getYear(start) && getYear(start) <= 2030 &&
            2010 <= getYear(end) && getYear(end) <= 2030;
    }


    renderStatsHeaderCells() {
        return (
            <>
                <th>В отпуске</th>
                <th>Занятость</th>
                <th>В периоде</th>
                <th>Рабочих</th>
                <th>В офисе</th>
                <th>В автомобиле</th>
                <th>В нерабочее</th>
                <th>Овертайм</th>
            </>
        )
    }

    renderStatsBodyCells() {
        const stats = this.state.stats;

        return (
            <>
                <td className="text-center">
                    {stats.vacation.days} <span className="text-tiny text-italic">д.</span></td>
                <td className="text-center">
                    {roundToTwo(stats.total.daysCoeff)} <span className="text-tiny text-italic">по д.</span><br/>
                    {roundToTwo(stats.total.hoursCoeff)} <span className="text-tiny text-italic">по ч.</span></td>
                <td className="text-center">
                    {roundToOne(stats.period.workingDays)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(stats.period.workingHours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(stats.total.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(stats.total.hours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(stats.office.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(stats.office.hours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(stats.field.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(stats.field.hours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(stats.onWeekend.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(stats.onWeekend.hours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(stats.overtime.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(stats.overtime.hours)} <span className="text-tiny text-italic">ч.</span></td>
            </>
        )
    }

    renderFirstTableRow() {
        const data = this.state.stats;

        if (!data)
            return null;

        const {displayType, showStats} = this.props.pageFilters;

        const vacationPay = rubFmt(data.vacation.payAmount);
        const officePay = rubFmt(data.office.payAmount);
        const fieldPay = rubFmt(data.field.payAmount);
        const totalPay = rubFmt(data.total.payAmount);
        const totalMeanPay = rubFmt(data.total.meanPayAmount);

        return (
            <tr>
                <td className="text-center">{vacationPay}</td>
                <td className="text-center">{officePay}</td>
                <td className="text-center">{fieldPay}</td>
                <td className="text-center">{totalPay}</td>
                <td className="text-center">{totalMeanPay}</td>
                {displayType === "oneTable" && showStats && this.renderStatsBodyCells()}
            </tr>
        )
    }

    renderPageFilters() {
        const {startDate, endDate, displayType, showStats, timespanMode} = this.props.pageFilters;

        return (
            <div className="columns">
                <div className="column col-4 col-lg-12">
                    <FormGroup>
                        <TimeNavigation defaultMode={timespanMode} onChange={this.onTimespanChange}
                                        defaultStartDate={startDate} defaultEndDate={endDate}/>
                    </FormGroup>
                </div>

                <div className="column col-2 col-lg-12"></div>

                <div className="column col-3 col-lg-12 d-flex align-end">
                    <FormGroup>
                        <Radio inline name="displayType" value="oneTable" onChange={this.onDisplayTypeChange}
                               checked={displayType === "oneTable"} disabled={!showStats}>
                            Одна таблица
                        </Radio>
                        <Radio inline name="displayType" value="twoTables" onChange={this.onDisplayTypeChange}
                               checked={displayType === "twoTables"} disabled={!showStats}>
                            Две таблицы
                        </Radio>
                    </FormGroup>
                </div>
                <div className="column col-3 col-lg-12 d-flex align-end">
                    <FormGroup>
                        <Checkbox name="showStats" onChange={this.onShowStatsChange} checked={showStats}>
                            Отобразить статистику
                        </Checkbox>
                    </FormGroup>
                </div>
            </div>
        )
    }

    render() {
        const {isLoading, loadingError, stats} = this.state;
        const currentUser = this.props.currentUser;

        if (isLoading || !stats)
            return <Loading large />;

        if (loadingError)
            return <Toast error>{loadingError.toString()}</Toast>;

        const {showStats, displayType} = this.props.pageFilters;

        const firstTableClassNames = cx({'table': true, 'table-striped': true, 'table-1600': displayType === "oneTable" && showStats});

        return (
            <div className="container" id="wages-engineers-table-page">
                <div className="columns">
                    {currentUser.isManagement && (
                        <div className="column col-12">
                             <WagesParamsDemonstration />
                        </div>
                    )}
                    {currentUser.isManagement && (
                        <div className="column col-12">
                            <Divider/>
                        </div>
                    )}
                    <div className="column col-12">
                        {this.renderPageFilters()}
                    </div>
                </div>

                <Divider />

                <table className={firstTableClassNames}>
                    <thead>
                        <tr className="text-tiny text-center">
                            <th>Отпускные</th>
                            <th>В офисе</th>
                            <th>В автомобиле</th>
                            <th>Всего</th>
                            <th>Всего ср-мес</th>
                            {displayType === "oneTable" && showStats && this.renderStatsHeaderCells()}
                        </tr>
                    </thead>
                    <tbody>
                    {this.renderFirstTableRow()}
                    </tbody>
                </table>

                <div className="divider my-4"/>

                {displayType === "twoTables" && showStats &&
                    <table className="table table-striped">
                        <thead>
                            <tr className="text-tiny text-center">
                                {this.renderStatsHeaderCells()}
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {this.renderStatsBodyCells()}
                        </tr>
                        </tbody>
                    </table>
                }
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.currentUser,
        pageFilters: state.pageFilters,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    setPageFilters: (state) => dispatch(setPageFilters(state))
});


export default connect(mapStateToProps, mapDispatchToProps)(WagesTablePage);
