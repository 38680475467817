import {DAY_STATUS} from "../../components/shared/sharedImport";

export function transformDayStatusToCSSClass(dayStatus) {
    switch (dayStatus) {
        case DAY_STATUS.VACATION_PAID: return 'vacationPaid';
        case DAY_STATUS.VACATION_UNPAID: return 'vacationUnpaid';
        case DAY_STATUS.TIME_OFF_PAID: return 'timeOffPaid';
        case DAY_STATUS.TIME_OFF_UNPAID: return 'timeOffUnpaid';
        case DAY_STATUS.OFFICE: return 'officeWork';
        case DAY_STATUS.OFFICE_NWD: return 'officeWorkNWD';
        case DAY_STATUS.FIELD: return 'fieldWork';
        case DAY_STATUS.FIELD_NWD: return 'fieldWorkNWD';
        case DAY_STATUS.ILLNESS: return 'illness';
        case DAY_STATUS.EMPTY: return null;
        default: return undefined;
    }
}