import React from 'react';
import cx from 'classnames';
import {
    fetchCategories, fetchRides, fetchSingleDayRides,
    removeRideGroups, submitRideGroups, submitRideGroupsInfo,
    toggleRideActiveStatus, toggleRideLinkedStatus,
    postManualRide, editManualRide, removeManualRide
} from "../api";
import {lightFormat, subDays} from "date-fns";
import PropTypes from "prop-types";
import {Modal, Button, Checkbox, Divider, Input, Label, Select, FormGroup, Toast} from "spectre-react";
import TestInput from "../components/shared/Input";
import TestButton from "../components/shared/Button";
import {RIDES_DOC_STATE} from "../constants";


class ManualRideModal extends React.Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        categories: PropTypes.object.isRequired,
        onToggle: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        submitState: PropTypes.number.isRequired,
        submitError: PropTypes.string,
        defaultDate: PropTypes.string,
        defaultTime0: PropTypes.string,
        defaultTime1: PropTypes.string,
        defaultCategoryId: PropTypes.string,
        defaultComment: PropTypes.string,
    }

    static defaultProps = {
        active: false,
        defaultDate: '',
        defaultTime0: '',
        defaultTime1: '',
        defaultCategoryId: '',
        defaultComment: '',
    }

    constructor(props) {
        super(props);

        this.state = {
            prefix: Math.random().toString(36).slice(2, 6),
        }
    }

    onSubmit = async (ev) => {
        ev.preventDefault();

        const prefix = this.state.prefix;

        const date = ev.target.elements[`${prefix}.date`].value;
        const time0 = ev.target.elements[`${prefix}.time0`].value;
        const time1 = ev.target.elements[`${prefix}.time1`].value;
        const categoryId = ev.target.elements[`${prefix}.categoryId`].value;
        const comment = ev.target.elements[`${prefix}.comment`].value;

        this.props.onSubmit(date, time0, time1, categoryId, comment);
    }

    render() {
        const categories = this.props.categories;

        const { prefix } = this.state;
        const {
            active,
            submitState, submitError,
            defaultDate, defaultTime0, defaultTime1, defaultCategoryId, defaultComment,
            onToggle,
        } = this.props;

        const isLoading = submitState === 1;
        const isSuccessfulSubmit = submitState === 2;

        return (
            <Modal active={active}>
                <Modal.Overlay onClick={onToggle} />
                <Modal.Container renderAs="form" onSubmit={this.onSubmit}>
                    <Modal.Header>
                      <Modal.Header.Title className='h5'>Добавить поездку вручную</Modal.Header.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FormGroup>
                            <Label form htmlFor={`${prefix}.date`}>Дата:</Label>
                            <TestInput loading={isLoading} name={`${prefix}.date`} id={`${prefix}.date`} defaultValue={defaultDate} type="date" required/>
                        </FormGroup>

                        <FormGroup>
                            <Label form htmlFor={`${prefix}.time0`}>Время начала поездки (HH:MM):</Label>
                            <TestInput loading={isLoading} name={`${prefix}.time0`} id={`${prefix}.time0`} defaultValue={defaultTime0} type="text" required placeholder="09:05" pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"/>
                        </FormGroup>

                        <FormGroup>
                            <Label form htmlFor={`${prefix}.time1`}>Время конца поездки (HH:MM):</Label>
                            <TestInput loading={isLoading} name="manualTide.time1" id={`${prefix}.time1`} defaultValue={defaultTime1} type="text" required placeholder="12:35" pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"/>
                        </FormGroup>

                        <FormGroup>
                            <Label form htmlFor={`${prefix}.categoryId`}>Категория:</Label>
                            <Select readOnly={isLoading} name={`${prefix}.categoryId`} id={`${prefix}.categoryId`} defaultValue={defaultCategoryId} required>
                                {Object.entries(categories).map(([categoryId, data]) =>
                                    <option key={categoryId} value={categoryId}>{data.name}</option>
                                )}
                            </Select>
                        </FormGroup>

                        <FormGroup>
                            <Label form htmlFor={`${prefix}.comment`}>Комментарий:</Label>
                            <TestInput loading={isLoading} name={`${prefix}.comment`} id={`${prefix}.comment`} defaultValue={defaultComment}/>
                        </FormGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        {submitError && <Toast className="mb-2" error>{submitError}</Toast>}

                      <TestButton primary type="submit" loading={isLoading} successState={isSuccessfulSubmit}>Отправить</TestButton>
                      <Button type="button" link onClick={onToggle} className="ml-2">Отмена</Button>
                    </Modal.Footer>
                </Modal.Container>
            </Modal>
        )
    }
}

class AddManualRideModal extends React.Component {
    static propTypes = {
        date: PropTypes.string,
        categories: PropTypes.object.isRequired,
        onSuccessfulSubmit: PropTypes.func.isRequired,
    }

    defaultState = {
        active: false,
        date: this.props.date || null, time0: null, time1: null, categoryId: null, comment: null,
        submitState: 0, submitError: undefined,
    }

    state = { ...this.defaultState };

    closeModal = () => this.setState(this.defaultState);

    openModal = () => this.setState({ active: true });

    handleToggle = () => this.state.active ? this.closeModal() : this.openModal();

    onSubmit = async (date, time0, time1, categoryId, comment) => {
        // the form is uncontrolled, and setState here saves values that are submitted so they aren't reset to defaults in case of error
        this.setState({date, time0, time1, categoryId, comment});

        const isSuccess = await this.doSubmit(date, time0, time1, categoryId, comment);
        if (isSuccess) {
            setTimeout(() => {
                this.setState(this.defaultState);
                this.props.onSuccessfulSubmit();
            }, 500);
        }
    }

    doSubmit = async (date, time0, time1, categoryId, comment) => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            await postManualRide(date, {time0, time1, categoryId, comment});
            this.setState({submitState: 2});
            return true;
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
            return false;
        }
    }

    render() {
        const {categories, children} = this.props;

        const {
            active, submitState, submitError,
            date, time0, time1, categoryId, comment
        } = this.state;

        return (
            <>
                {children(this.handleToggle)}
                <ManualRideModal key={active}
                    active={active} categories={categories}
                    onSubmit={this.onSubmit} onToggle={this.handleToggle}
                    submitState={submitState} submitError={submitError}
                    defaultDate={date} defaultTime0={time0} defaultTime1={time1}
                    defaultCategoryId={categoryId} defaultComment={comment}
                />
            </>
        );
    }
}

class EditManualRideModal extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        categories: PropTypes.object.isRequired,
        onSuccessfulSubmit: PropTypes.func.isRequired,
    }

    defaultState = {
        active: false,
        date: this.props.date, time0: this.props.time0, time1: this.props.time1, categoryId: this.props.categoryId, comment: this.props.comment,
        submitState: 0, submitError: undefined,
    }

    state = { ...this.defaultState };

    closeModal = () => this.setState(this.defaultState);

    openModal = () => this.setState({ active: true });

    handleToggle = () => this.state.active ? this.closeModal() : this.openModal();

    onSubmit = async (date, time0, time1, categoryId, comment) => {
        // the form is uncontrolled, and setState here saves values that are submitted so they aren't reset to defaults in case of error
        this.setState({date, time0, time1, categoryId, comment});

        const isSuccess = await this.doSubmit(date, time0, time1, categoryId, comment);
        if (isSuccess) {
            setTimeout(() => {
                this.setState(this.defaultState);
                this.props.onSuccessfulSubmit();
            }, 500);
        }
    }

    doSubmit = async (date, time0, time1, categoryId, comment) => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            const index = this.props.index;
            await editManualRide(date, index, {time0, time1, categoryId, comment});
            this.setState({submitState: 2});
            return true;
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
            return false;
        }
    }

    render() {
        const {categories, children} = this.props;

        const {
            active, submitState, submitError,
            date, time0, time1, categoryId, comment
        } = this.state;

        return (
            <>
                {children(this.handleToggle)}
                <ManualRideModal key={active}
                    active={active} categories={categories}
                    onSubmit={this.onSubmit} onToggle={this.handleToggle}
                    submitState={submitState} submitError={submitError}
                    defaultDate={date} defaultTime0={time0} defaultTime1={time1}
                    defaultCategoryId={categoryId} defaultComment={comment}
                />
            </>
        );
    }
}


class RemoveManualRideModal extends React.Component {
    static propTypes = {
        date: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        onSuccessfulSubmit: PropTypes.func.isRequired,
    }

    defaultState = {
        active: false,
        submitState: 0, submitError: undefined,
    }

    state = { ...this.defaultState };

    closeModal = () => this.setState(this.defaultState);

    openModal = () => this.setState({ active: true });

    handleToggle = () => this.state.active ? this.closeModal() : this.openModal();

    onSubmit = async () => {
        const isSuccess = await this.doSubmit();

        if (isSuccess) {
            setTimeout(() => {
                this.setState(this.defaultState);
                this.props.onSuccessfulSubmit();
            }, 500);
        }
    }

    doSubmit = async () => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            const {date, index} = this.props;
            await removeManualRide(date, index);
            this.setState({submitState: 2});
            return true;
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
            return false;
        }
    }

    render() {
        const {children} = this.props;
        const {active, submitState, submitError} = this.state;
        const isLoading = submitState === 1;
        const isSuccessfulSubmit = submitState === 2;

        return (
            <>
                {children(this.handleToggle)}
                <Modal small active={active}>
                    <Modal.Overlay onClick={this.handleToggle} />
                    <Modal.Container>
                        <Modal.Header>
                          <Modal.Header.Title className='h5'>Удалить поездку?</Modal.Header.Title>
                        </Modal.Header>

                        <Modal.Body>
                        </Modal.Body>

                        <Modal.Footer>
                            {submitError && <Toast className="mb-2" error>{submitError}</Toast>}

                            <TestButton primary type="button" onClick={this.onSubmit} loading={isLoading} successState={isSuccessfulSubmit}>Удалить</TestButton>
                            <Button type="button" link onClick={this.handleToggle} className="ml-2">Отмена</Button>
                        </Modal.Footer>
                    </Modal.Container>
                </Modal>
            </>
        );
    }
}



class ManualRide extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        item: PropTypes.shape({
            time0: PropTypes.string.isRequired,
            time1: PropTypes.string.isRequired,
            categoryId: PropTypes.string.isRequired,
            comment: PropTypes.string.isRequired,
        }).isRequired,
        categories: PropTypes.object.isRequired,
        reloadDoc: PropTypes.func.isRequired,
    }

    reloadRidesDoc = () => {
        this.props.reloadDoc(this.props.date);
    }

    render() {
        const {index, date, item, categories} = this.props;

        const intervalCls = cx({"text-primary": item.hasIntersection, "tooltip tooltip-right": item.hasIntersection});
        const intervalTooltip = item.hasIntersection ? "Есть пересечение с поездкой с трекера" : undefined;

        const commentCls = cx({"text-primary": !!item.comment, "text-gray": !item.comment});

        return (
            <div className="manual-ride" data-ride-index={index}>
                <div className="manual-ride__interval">
                    <span className={cx(intervalCls)} data-tooltip={intervalTooltip}>{item.time0} – {item.time1}</span>
                </div>
                <div className="manual-ride__categoryId">
                    {/*<span>Категория: </span>*/}
                    <span className="text-italic" title="Категория">{categories[item.categoryId].name}</span>
                </div>
                <div className="manual-ride__comment">
                    {/*<span>Комментарий: </span>*/}
                    <span className={commentCls} title="Комментарий">{item.comment || "<нет комментария>"}</span>
                </div>
                <div className="manual-ride__actions">
                    <EditManualRideModal date={date} index={index} categories={categories} {...item} onSuccessfulSubmit={this.reloadRidesDoc}>
                        {(openModal) => (
                            <Button link onClick={openModal}>
                                <i className="material-icons">edit</i>
                            </Button>
                        )}
                    </EditManualRideModal>
                    <RemoveManualRideModal date={date} index={index} onSuccessfulSubmit={this.reloadRidesDoc}>
                        {(openModal) => (
                            <Button link onClick={openModal}>
                                <i className="material-icons">delete</i>
                            </Button>
                        )}
                    </RemoveManualRideModal>
                </div>
            </div>
        );
    }
}


class TrackerRide extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        item: PropTypes.shape({
            time0: PropTypes.string.isRequired,
            time1: PropTypes.string.isRequired,
            address0: PropTypes.string.isRequired,
            address1: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired,
            isLinked: PropTypes.bool.isRequired,
        }).isRequired,
        toggleActiveStatus: PropTypes.func,
        toggleLinkedStatus: PropTypes.func,
        showLinkers: PropTypes.bool,
        showActiveButton: PropTypes.bool,
        showLinkButton: PropTypes.bool,
    }

    static defaultProps = {
        showLinkButton: false,
        showActiveButton: false,
    }

    state = {
        isLoading: false,
    }

    toggleActiveStatus = async (ev) => {
        const btn = ev.currentTarget;
        const row = btn.parentElement.parentElement;
        const index = parseInt(row.dataset.rideIndex, 10);

        this.setState({isLoading: true});
        await this.props.toggleActiveStatus(this.props.date, index);
        this.setState({isLoading: false});
    }

    toggleLinkedStatus = async (ev) => {
        const btn = ev.currentTarget;
        const row = btn.nextElementSibling;
        const index = parseInt(row.dataset.rideIndex, 10);

        this.setState({isLoading: true});
        await this.props.toggleLinkedStatus(this.props.date, index);
        this.setState({isLoading: false});
    }

    render() {
        const {index, item, showActiveButton, showLinkButton} = this.props;
        const isLoading = this.state.isLoading;
        const {isLinkedToNext, isLinkedToPrev, isActive, isPrevActive} = item;

        const isLinkerDisabled = (!isActive || !isPrevActive) || !showLinkButton;
        const linkerDivCls = cx(
            "tracker-rides-linker",
            {
                "tracker-rides-linker__link": !isLinkedToPrev && !isLinkerDisabled,
                "tracker-rides-linker__unlink": isLinkedToPrev,
                // "tracker-rides-linker__disabled": isLinkerDisabled
            }
        );

        const rideRowCls = cx(
            "tracker-ride",
            {
                "tracker-ride-disabled": !isActive,
                "tracker-ride-linked": isLinkedToPrev || isLinkedToNext,
                "mt-1": !isLinkedToPrev,
                "mb-1": !isLinkedToNext
            }
        );

        return (
            <>
                {index > 0 && !isLinkerDisabled && (
                    <div className={linkerDivCls} onClick={this.toggleLinkedStatus}>
                        <i className="material-icons">{item.isLinkedToPrev ? "link_off" : "add_link"}</i>
                    </div>
                )}
                <div className={rideRowCls} data-ride-index={index}>
                    <div className="tracker-ride__point">
                        <div className={cx("tracker-ride__time", {'d-invisible': isLinkedToPrev})}>{item.time0}</div>
                        <div className="tracker-ride__address text-ellipsis">{item.address0}</div>
                    </div>
                    <div className="tracker-ride__point">
                        <div className={cx("tracker-ride__time", {'d-invisible': isLinkedToNext})}>{item.time1}</div>
                        <div className="tracker-ride__address text-ellipsis">{item.address1}</div>
                    </div>
                    <div className="tracker-ride__actions ml-5">
                        <Button link className={cx({
                            'text-success': item.isActive,
                            'text-error': !item.isActive,
                            'loading': isLoading,
                            'd-none': !showActiveButton
                        })} onClick={this.toggleActiveStatus}>
                            <i className="material-icons">{item.isActive ? "check_circle" : "unpublished"}</i>
                        </Button>
                        <Button link href={`https://yandex.ru/maps?rtext=${item.lat0},${item.lng0}~${item.lat1},${item.lng1}&rtt=auto`} target="_blank" rel="noopener noreferrer">
                            <i className="material-icons">map</i>
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}


class TrackerRidesGroup extends React.Component {w
    static propTypes = {
        date: PropTypes.string.isRequired,
        group: PropTypes.object.isRequired,
        rides: PropTypes.arrayOf(PropTypes.object).isRequired,
        index: PropTypes.number.isRequired,
        categories: PropTypes.objectOf(PropTypes.object).isRequired,
        isEditing: PropTypes.bool.isRequired,
    }

    static defaultProps = {
        isEditing: false
    }

    render() {
        const {date, group, rides, index, categories, isEditing} = this.props;

        const {rideIds, categoryId, isActive, comment} = group;

        if (!isActive)
            return null;

        const firstRide = rides[rideIds.at(0)];
        const lastRide = rides[rideIds.at(-1)];

        const {time0, lat0, lng0} = firstRide;
        const {time1, lat1, lng1} = lastRide;

        const intervalCls = cx({"text-primary": group.hasIntersection, "tooltip tooltip-right": group.hasIntersection});
        const intervalTooltip = group.hasIntersection ? "Есть пересечение с ручной поездкой" : undefined;

        const commentCls = cx({"text-primary": !!comment, "text-gray": !comment});

        return (
            <div className="tracker-ride-group" data-ride-index={index}>
                <div className="tracker-ride-group__interval">
                    <span className={intervalCls} data-tooltip={intervalTooltip}>{time0} – {time1}</span>
                </div>
                {!isEditing && (
                    <div className="tracker-ride-group__fields">
                        <div className="tracker-ride-group__categoryId">
                            <span className="text-italic" title="Категория">{categoryId ? categories[categoryId].name : ''}</span>
                        </div>
                        <div className="tracker-ride-group__comment">
                            <span className={commentCls} title="Комментарий">{comment || "<нет комментария>"}</span>
                        </div>
                    </div>
                )}
                {isEditing && (
                    <form className="tracker-ride-group__form">
                        <div className="tracker-ride-group__form-field tracker-ride-group__form_category">
                            {/*<Label form htmlFor={`${date}:${index}:categoryId`} className="mr-2">Категория:</Label>*/}
                            <Select small defaultValue={categoryId} name={`${date}:${index}:categoryId`} id={`${date}:${index}:categoryId`}>
                                {Object.entries(categories).map(([categoryId, data]) =>
                                    <option key={categoryId} value={categoryId}>{data.name}</option>
                                )}
                            </Select>
                        </div>
                        <div className="tracker-ride-group__form-field tracker-ride-group__form-comment">
                            {/*<Label form htmlFor={`${date}:${index}:comment`} className="mr-2">Комментарий:</Label>*/}
                            <Input small name={`${date}:${index}:comment`} id={`${date}:${index}:comment`}
                                   defaultValue={comment} className="ride-group-form__comment" placeholder="Комментарий"/>
                        </div>
                    </form>
                )}
                <div className="tracker-ride__actions">
                    <Button link href={`https://yandex.ru/maps?rtext=${lat0},${lng0}~${lat1},${lng1}&rtt=auto`} target="_blank" rel="noopener noreferrer">
                        <i className="material-icons">map</i>
                    </Button>
                </div>
            </div>
        )
    }
}


class RidesDoc extends React.Component {
    static propTypes = {
        doc: PropTypes.object,
        categories: PropTypes.object,
        reloadDoc: PropTypes.func,
        submitGroups: PropTypes.func,
        removeGroups: PropTypes.func,
        submitRideGroupsInfo: PropTypes.func,
        toggleRideActiveStatus: PropTypes.func,
        toggleRideLinkedStatus: PropTypes.func,
        selectedCategoryId: PropTypes.string,
        showOnlyUnfinished: PropTypes.bool,
    }

    state = {
        isDisplayingRawInfo: false,
        isEditing: this.props.doc.state === RIDES_DOC_STATE.GROUPED,
    }

    toggleRawInfo = () => {
        const isDisplayingRawInfo = !this.state.isDisplayingRawInfo;
        const isEditing = isDisplayingRawInfo ? false : this.state.isEditing;
        this.setState({isDisplayingRawInfo, isEditing});
    }

    toggleEdit = () => {
        this.setState({isEditing: !this.state.isEditing});
    }

    onSubmitGroupsButtonClick = async (ev) => {
        const date = ev.currentTarget.parentElement.parentElement.parentElement.dataset.date;
        await this.props.submitGroups(date);
        await this.props.reloadDoc(date);
    }

    onRemoveGroupsButtonClick = async (ev) => {
        const date = ev.currentTarget.parentElement.parentElement.parentElement.dataset.date;
        await this.props.removeGroups(date);
        await this.props.reloadDoc(date);
    }

    onSubmitRideInfoButtonClick = async (ev) => {
        const summaryEl = ev.currentTarget.parentElement.parentElement.parentElement;

        const date = summaryEl.dataset.date, data = {};
        const inputs = [...summaryEl.querySelectorAll(`select[id^="${date}"]`), ...summaryEl.querySelectorAll(`input[id^="${date}"]`)];
        for (const input of inputs) {
            let [_, index, field] = input.name.split(':');
            index = parseInt(index, 10);
            data[index] = {...data[index], [field]: input.value};
        }

        const success = await this.props.submitRideGroupsInfo(date, data);
        if (success) {
            this.toggleEdit();
            await this.props.reloadDoc(date);
        }
    }

    renderListActions() {
        const {doc, selectedCategoryId} = this.props;
        const {isDisplayingRawInfo, isEditing} = this.state;
        const isDisabled = !!selectedCategoryId || doc.rides.length == 0;

        if (doc.state === RIDES_DOC_STATE.RAW) {
            return (
                <React.Fragment>
                    <Button type="button" small primary onClick={this.onSubmitGroupsButtonClick} disabled={isDisabled}>
                        GROUP
                    </Button>
                </React.Fragment>
            )
        } else if (doc.state === RIDES_DOC_STATE.GROUPED) {
            return (
                <React.Fragment>
                    <Button type="button" small success onClick={this.onSubmitRideInfoButtonClick} disabled={isDisabled}>
                        <i className="material-icons">done_all</i>
                        {/*<i className="material-icons">check_circle</i>*/}
                        {/*<span>SUBMIT INFO</span>*/}
                    </Button>
                </React.Fragment>
            );
        } else if (doc.state === RIDES_DOC_STATE.FINISHED) {
            if (isEditing) {
                return (
                    <React.Fragment>
                        <Button type="button" small error onClick={this.toggleEdit} className="btn-material-icon-text mr-2" disabled={isDisabled}>
                            <i className="material-icons">cancel</i>
                            <span>Cancel</span>
                        </Button>
                        <Button type="button" small success onClick={this.onSubmitRideInfoButtonClick} className="btn-material-icon-text" disabled={isDisabled}>
                            <i className="material-icons">check_circle</i>
                            <span>Submit</span>
                        </Button>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        {!isDisplayingRawInfo && (
                            <Button type="button" small primary onClick={this.toggleEdit} className="mr-2" disabled={isDisabled}>
                                <i className="material-icons">edit</i>
                                {/*<span>EDIT</span>*/}
                            </Button>
                        )}
                        <Button type="button" small onClick={this.toggleRawInfo} className="mr-2" disabled={isDisabled}>
                            {isDisplayingRawInfo && <i className="material-icons">visibility_off</i>}
                            {!isDisplayingRawInfo && <i className="material-icons">visibility</i>}
                            {/*<span>{isDisplayingRawInfo ? "SEE GROUPS" : "SEE RAW INFO"}</span>*/}
                        </Button>
                        <Button type="button" small primary onClick={this.onRemoveGroupsButtonClick} disabled={isDisabled}>
                            UNGROUP
                        </Button>
                    </React.Fragment>
                )
            }
        } else {
            return null;
        }
    }

    renderManualRides() {
        const {doc, categories, selectedCategoryId, reloadDoc} = this.props;
        // const {isDisplayingRawInfo, isEditing} = this.state;

        let rides = doc.manual;

        if (selectedCategoryId)
            rides = rides.filter(ride => ride.categoryId === selectedCategoryId);

        return rides
            .map((item, index) =>
                <ManualRide
                    key={doc.date + item.time0 + item.time1 + item.categoryId}
                    index={index}
                    date={doc.date}
                    item={item}
                    categories={categories}
                    reloadDoc={reloadDoc}
                />
            );
    }

    renderTrackerRides() {
        const {doc, categories, selectedCategoryId} = this.props;
        const {toggleRideActiveStatus, toggleRideLinkedStatus} = this.props;

        const {isDisplayingRawInfo, isEditing} = this.state;

        if (selectedCategoryId) {
            if (doc.state !== RIDES_DOC_STATE.FINISHED)
                return null;

            return doc.groups
                .filter(group => group.categoryId === selectedCategoryId)
                .map((group, index) =>
                <TrackerRidesGroup key={doc.date + doc.rides[group.rideIds[0]].time0}
                    date={doc.date}
                    group={group}
                    rides={doc.rides}
                    categories={categories}
                    index={index}
                    isEditing={isEditing}
                />
            );
        }

        if (doc.state === RIDES_DOC_STATE.RAW) {
            return doc.rides.map((item, index) =>
                <TrackerRide key={doc.date + item.time0 + item.isActive + item.isLinked}
                      date={doc.date}
                      index={index}
                      item={item}
                      toggleActiveStatus={toggleRideActiveStatus}
                      toggleLinkedStatus={toggleRideLinkedStatus}
                      showActiveButton
                      showLinkButton
                />
            );
        } else if (doc.state === RIDES_DOC_STATE.GROUPED) {
            return doc.groups.map((group, index) =>
                <TrackerRidesGroup key={doc.date + doc.rides[group.rideIds[0]].time0}
                    date={doc.date}
                    group={group}
                    rides={doc.rides}
                    categories={categories}
                    index={index}
                    isEditing
                />
            );
        } else if (doc.state === RIDES_DOC_STATE.FINISHED) {
            if (isDisplayingRawInfo) {
                return doc.rides.map((item, index) =>
                    <TrackerRide key={doc.date + item.time0 + item.isActive + item.isLinked}
                          date={doc.date}
                          index={index}
                          item={item}
                    />
                );
            } else {
                return doc.groups.map((group, index) =>
                    <TrackerRidesGroup key={doc.date + doc.rides[group.rideIds[0]].time0}
                        date={doc.date}
                        group={group}
                        rides={doc.rides}
                        categories={categories}
                        index={index}
                        isEditing={isEditing}
                    />
                );
            }
        } else {
            return null;
        }
    }

    render() {
        const doc = this.props.doc;
        const showOnlyUnfinished = this.props.showOnlyUnfinished;

        if (showOnlyUnfinished && doc.state === RIDES_DOC_STATE.FINISHED)
            return null;

        return (
            <details className="accordion" key={doc.date} open data-date={doc.date}>
                <summary className="accordion-header">
                    <i className="icon icon-arrow-right mr-1"></i>
                    <span className="text-bold">{doc.dateMMM}</span>
                </summary>
                <div className="accordion-body">
                    <div id="rides-list-actions">
                        {this.renderListActions()}
                    </div>
                    {this.renderManualRides()}
                    {doc.manual.length > 0 && doc.rides.length > 0 && <Divider />}
                    {this.renderTrackerRides()}
                </div>
            </details>
        )
    }
}


class RidesPage extends React.PureComponent {
    static propTypes = {};

    state = {
        selectedCategoryId: '',
        showOnlyUnfinished: false,
        selectedEndDate: lightFormat(new Date(), "yyyy-MM-dd"),
        selectedStartDate: lightFormat(subDays(new Date(), 14), 'yyyy-MM-dd'),

        catsLoadState: 0, catsLoadError: undefined, categories: {},
        docsLoadState: 0, docLoadError: undefined, docs: [],

        groupsSubmitState: 0, groupsSubmitError: undefined,
        infoSubmitState: 0, infoSubmitError: undefined,

        isManualRideModalActive: false,

        showLoadMoreBlock: true,
    }

    async componentDidMount() {
        const {selectedStartDate, selectedEndDate} = this.state;
        await this.fetchCategories();
        await this.fetchData(selectedStartDate, selectedEndDate);
    }

    fetchCategories = async () => {
        this.setState({catsLoadState: 1, catsLoadError: undefined});

        try {
            const response = await fetchCategories();
            const categories = {};

            for (const item of response) {
                const {id, ...rest} = item;
                categories[id] = rest;
            }

            this.setState({catsLoadState: 2, categories: categories});
        } catch (error) {
            console.error(error);
            this.setState({catsLoadState: 0, catsLoadError: error.message});
        }
    }

    fetchData = async (startDate, endDate, concat = false) => {
        this.setState({docsLoadState: 1, docsLoadError: undefined});

        try {
            const docs = await fetchRides(startDate, endDate, "desc");

            for (let doc of docs) {
                for (let {groupId, manualId} of doc.intersections) {
                    doc.manual[manualId].hasIntersection = true;
                    doc.groups[groupId].hasIntersection = true;
                }
            }

            if (concat) {
                if (docs.length > 0)
                    this.setState(prevState => ({docsLoadState: 2, docs: [...prevState.docs, ...docs]}));
            } else {
                this.setState({docsLoadState: 2, docs});
            }
        } catch (error) {
            console.error(error);
            this.setState({docsLoadState: 0, docsLoadError: error.message});
        }
    }

    updateData = async () => {
        const {selectedStartDate, selectedEndDate} = this.state;
        await this.fetchData(selectedStartDate, selectedEndDate);
    }

    doReloadDoc = async (date) => {
        this.setState({docsLoadState: 1, docsLoadError: undefined});

        try {
            const doc = await fetchSingleDayRides(date);

            for (let {groupId, manualId} of doc.intersections) {
                doc.manual[manualId].hasIntersection = true;
                doc.groups[groupId].hasIntersection = true;
            }

            this.setState(prevState => ({
                docs: prevState.docs.map(prevDoc => prevDoc.date === doc.date ? doc : prevDoc),
                docsLoadState: 2,
            }));
        } catch (error) {
            console.error(error);
            this.setState({docsLoadState: 0, docsLoadError: error.message});
        }
    }

    onLoadMoreDataButtonClick = async (ev) => {
        const daysCount = parseInt(ev.currentTarget.innerText, 10);

        const loadEndDate = lightFormat(subDays(new Date(this.state.selectedStartDate), 1), 'yyyy-MM-dd');
        const loadStartDate = lightFormat(subDays(new Date(loadEndDate), daysCount - 1), 'yyyy-MM-dd');

        const docsCountBeforeFetch = this.state.docs.length;
        await this.fetchData(loadStartDate, loadEndDate, true);
        const docsCountAfterFetch = this.state.docs.length;

        const showLoadMoreBlock = docsCountBeforeFetch !== docsCountAfterFetch;
        this.setState({showLoadMoreBlock, selectedStartDate: loadStartDate});
    }

    toggleRideActiveStatus = async (date, rideIndex) => {
        try {
            await toggleRideActiveStatus(date, rideIndex);
            await this.doReloadDoc(date);
        } catch (error) {
            alert(error);
            console.error(error);
        }
    }

    toggleRideLinkedStatus = async (date, rideIndex) => {
        try {
            await toggleRideLinkedStatus(date, rideIndex);
            await this.doReloadDoc(date);
        } catch (error) {
            alert(error);
            console.error(error);
        }
    }

    doSubmitGroups = async (date) => {
        this.setState({groupsSubmitState: 1, groupsSubmitError: undefined});

        try {
            await submitRideGroups(date);
            this.setState({groupsSubmitState: 2});
        } catch (error) {
            console.error(error);
            alert(error);
            this.setState({groupsSubmitState: 0, groupsSubmitError: error.message});
        }
    }

    doRemoveGroups = async (date) => {
        this.setState({groupsSubmitState: 1, groupsSubmitError: undefined});

        try {
            await removeRideGroups(date);
            this.setState({groupsSubmitState: 2});
        } catch (error) {
            console.error(error);
            alert(error);
            this.setState({groupsSubmitState: 0, groupsSubmitError: error.message});
        }
    }

    doSubmitRideGroupsInfo = async (date, data) => {
        this.setState({infoSubmitState: 1, infoSubmitError: undefined});

        try {
            await submitRideGroupsInfo(date, data);
            this.setState({infoSubmitState: 2});
            return true;
        } catch (error) {
            console.error(error);
            alert(error);
            this.setState({infoSubmitState: 0, infoSubmitError: error.message});
            return false;
        }
    }

    onCategoryFilterChange = (ev) => {
        const value = ev.target.value;
        this.setState({selectedCategoryId: value});
    }

    onTimeFilterChange = (ev) => {
        const name = ev.target.name;
        const value = ev.target.value;
        if (value)
            this.setState({[name]: value}, this.updateData);
    }

    onShowOnlyUnfinishedChange = (ev) => {
        const value = ev.target.checked;
        this.setState({showOnlyUnfinished: value, selectedCategoryId: ''});
    }

    render() {
        const {docs, categories, selectedCategoryId, selectedStartDate, selectedEndDate, showOnlyUnfinished} = this.state;
        const showLoadMoreBlock = this.state.showLoadMoreBlock;

        return (
            <div>
                <div className="columns">
                    <div className="column col-3">
                        <Checkbox onChange={this.onShowOnlyUnfinishedChange} value={showOnlyUnfinished}>
                            Только необработанные дни
                        </Checkbox>
                    </div>
                    <div className="column col-2">
                        <Select onChange={this.onCategoryFilterChange} value={selectedCategoryId}>
                            <option key="null" value="">{"<все категории>"}</option>
                            {Object.entries(categories).map(
                                ([id, category]) => <option key={id} value={id}>{category.name}</option>)
                            }
                        </Select>
                    </div>
                    <div className="column col-1"/>
                    <div className="column col-4 d-flex flex-centered">
                        С <Input type="date" name="selectedStartDate" value={selectedStartDate} onChange={this.onTimeFilterChange} className="mx-2"/>
                        по <Input type="date" name="selectedEndDate" value={selectedEndDate} onChange={this.onTimeFilterChange} className="mx-2"/>
                    </div>
                    <div className="column col-2">
                        <AddManualRideModal categories={categories} onSuccessfulSubmit={this.updateData} buttonProps={{primary: true, className: "float-right"}}>
                            {(openModal) => (
                                <Button primary className="float-right" onClick={openModal}>
                                    <i className="material-icons">add</i>
                                    {/*<span>ADD</span>*/}
                                </Button>
                            )}
                        </AddManualRideModal>
                    </div>
                </div>

                <Divider/>

                <div id="rides-list-wrapper">
                    {docs.map(doc =>
                        <RidesDoc
                            key={doc.date + doc.state}
                            doc={doc}
                            selectedCategoryId={selectedCategoryId}
                            showOnlyUnfinished={showOnlyUnfinished}
                            reloadDoc={this.doReloadDoc}
                            submitGroups={this.doSubmitGroups}
                            removeGroups={this.doRemoveGroups}
                            submitRideGroupsInfo={this.doSubmitRideGroupsInfo}
                            toggleRideActiveStatus={this.toggleRideActiveStatus}
                            toggleRideLinkedStatus={this.toggleRideLinkedStatus}
                            categories={categories}
                        />
                    )}
                </div>

                {showLoadMoreBlock && (
                    <div className="text-center d-flex justify-center align-center">
                        <span className="mr-2">Загрузить еще</span>
                        <Button link className="mr-2" onClick={this.onLoadMoreDataButtonClick}>15</Button>
                        <Button link className="mr-2" onClick={this.onLoadMoreDataButtonClick}>30</Button>
                        <Button link className="mr-2" onClick={this.onLoadMoreDataButtonClick}>60</Button>
                        <Button link className="mr-2" onClick={this.onLoadMoreDataButtonClick}>120</Button>
                        <span>дней</span>
                    </div>
                )}
            </div>
        );
    }
}


export default RidesPage;
