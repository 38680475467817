import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'


const propTypes = {
  action: PropTypes.bool,
  active: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.node,
  circle: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  large: PropTypes.bool,
  link: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  small: PropTypes.bool,
  success: PropTypes.bool,
    successState: PropTypes.bool,
  renderAs: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
}

const defaultProps = {
  renderAs: 'button'
  // onClick: () => null
}

const Button = ({ children, ...props }) => {
    const {
        className,
        onClick,
        renderAs,

        // styles
        primary,
        link,

        // colors
        success,
        error,

        // sizes
        block,
        small,
        large,
        action,
        circle,

        // states
        active,
        loading,
        disabled,
        successState,

        ...attributes
    } = props

    const classNames = classnames(
        'btn',

        {
            'btn-primary': primary,
            'btn-link': link,
            'btn-success': success || successState,
            'btn-error': error,
            'btn-block': block,
            'btn-sm': small,
            'btn-lg': large,
            'btn-action': action || circle,
            's-circle': circle,
            active: active,
            loading: loading,
            disabled: successState ? true : disabled
        },

        className
    )

    const Element = (attributes.href) ? 'a' : renderAs;
    const type = (Element === 'button' && onClick) ? 'button' : undefined

    children = successState ? <i className="icon icon-check"></i> : children;

    return (
        <Element
            type={type}
            {...attributes}
            onClick={disabled ? undefined : onClick}
            className={classNames}
        >
            {children}
        </Element>
    )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
