import React from "react";
import PropTypes from "prop-types";
import {eachDayOfInterval, lightFormat} from "date-fns";
import {DAY_STATUS} from "../../components/shared/sharedImport";


class StatusLegend extends React.PureComponent {
    static propTypes = {
        timeline: PropTypes.object,
        selectRange: PropTypes.bool.isRequired,
        selectedRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired
    }

    calculateStatusCounts() {
        if (!this.props.selectRange)
            return {};

        const statusCount = Object.fromEntries(Object.keys(DAY_STATUS).map(key => [key, 0]));
        statusCount['OVERRIDE'] = 0;

        const [start, end] = this.props.selectedRange;
        for (const date of eachDayOfInterval({start, end})) {
            const dateStr = lightFormat(date, 'yyyy-MM-dd');
            const dayProps = this.props.timeline[dateStr];
            const status = dayProps.statusOverride ?? dayProps.defaultStatus;
            statusCount[status] += 1;

            if (dayProps.statusOverride)
                statusCount.OVERRIDE += 1;
        }
        return statusCount
    }

    render() {
        const showCounts = this.props.selectRange;
        const statusCount = this.calculateStatusCounts();

        return (
            <ul id="legend" className='color-legend'>
                <li><span className="vacationUnpaid"/><span>Неоплачиваемый отпуск, неоплачиваемый отгул</span>{showCounts && ` — ${statusCount[DAY_STATUS.VACATION_UNPAID]}`}</li>
                <li><span className="vacationPaid"/><span>Оплачиваемый отпуск, оплачиваемый отгул, больничный</span>{showCounts && ` — ${statusCount[DAY_STATUS.VACATION_PAID]}`}</li>
                <li><span className="officeWork"/><span>В офисе</span>{showCounts && ` — ${statusCount[DAY_STATUS.OFFICE]}`}</li>
                <li><span className="officeWorkNWD"/><span>В офисе в нерабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.OFFICE_NWD]}`}</li>
                <li><span className="fieldWork"/><span>В автомобиле</span>{showCounts && ` — ${statusCount[DAY_STATUS.FIELD]}`}</li>
                <li><span className="fieldWorkNWD"/><span>В автомобиле в нерабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.FIELD_NWD]}`}</li>
                <li><span className="badge"/><span>Статус дня был изменен (красная точка)</span>{showCounts && ` — ${statusCount.OVERRIDE}`}</li>
                <li><span/><span>Пустой день</span>{showCounts && ` — ${statusCount.EMPTY}`}</li>
            </ul>
        )
    }
}

export default StatusLegend;